.dialog-box {
  .MuiDialog-paper {
    padding: 1.5rem;
    min-width: 30rem;
  }
  color: #333333;
  .dialog-title {
    padding: 0rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }
  .dialog-content {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0rem;
  }
}
.dialog-box-baseline {
  .MuiDialog-paper {
    padding: 1rem;
    width: 31.25rem;
  }
}

.transactions-wrapper {
  max-height: 6.25rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
}

.impacted-transactions {
  color: #333333;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
