body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f1f1f1;
}
.map-container {
  height: 100%;
  width: 100%;
}
.add-marker-button {
  position: absolute;
  z-index: 999;
  margin-left: 200px;
  padding: 0.8em;
  margin-top: 0.8em;
}

.search-column {
  position: absolute;
  z-index: 999;
  padding: 0.6em;
  padding-top: 0.7em;
  top: 0;
  right: 3rem;
}

.curved-border {
  border-radius: 2px;
}

.pac-container {
  z-index: 999999;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
  padding: 0.5rem;
}

.gm-style-iw-d {
  font-size: 0.75rem;
  width: 17.375rem;
  height: 5rem;
  margin-bottom: 0.5rem;
  margin-top: -2.125rem;
}
.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
.map-container.summary-map .gm-style .gm-style-iw-d {
  overflow: hidden !important;
  display: flex;
  display: -webkit-flex;
  width: max-content !important;
  height: max-content !important;
}
.map-container.summary-map .gm-style .gm-style-iw-c {
  display: flex;
  display: -webkit-flex;
  max-width: max-content !important;
  max-height: max-content !important;
}

.App {
  position: relative;
}

.styled-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d0d0ce;
  border-radius: 3px;
}

.participant-flow {
  min-height: calc(100vh - 14em);
  overflow: auto;
}

.participant-flow > .MuiBox-root {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  height: calc(100vh - 14.5em);
  margin-bottom: 0px;
}

.participant-flow > .MuiBox-root > div > div > :nth-child(1) {
  padding: 0 1.5em 2.5em 1.5em;
}

.gm-ui-hover-effect {
  z-index: 1000 !important ;
  transform: translateY(0.14rem) !important;
} /*this is use for the close icon of hover over the fields in map*/

.ml-4 {
  margin-left: 4px;
}
